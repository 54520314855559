import { http } from './config'
import authHeader from './auth-header';

export default {


    fluxo: (dataI, dataF, nomeEmpresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa
        }
        return http.get('/fluxocaixa-dash/fluxo', {
           params: params,
           headers: authHeader()   
        })
    },

}
