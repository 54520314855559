<template>
 
  <v-app style="background-color:#f8f9fb" >

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a {{ 
          this.dias }} dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

   
    <!-- Diálogo de progresso -->
    <v-dialog v-model="showProgress" persistent max-width="300">
    <v-card>
      <v-card-title>
        Baixando arquivo...
      </v-card-title>
      <v-card-text>
        <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>



  <v-container >
      <v-col cols="12" >
        <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'ano'" :produtos="tipos_funerarios" 
        @filtrar="filterData" :title_opcao="'Situação'"
          ></filtro-component>
      </v-col>
    </v-container>


  <v-container style="background-color:#eee"  
    id="fluxo"
    fluid
    tag="section">
 
    <v-row>
    
    <v-col
    cols="12">

        <base-material-card
        icon="mdi-cash"
        title="Fluxo de Caixa"
        color="red" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0">

         
                  <v-data-table
                      :headers="fluxo_header"
                      :items="fluxos"
                      :items-per-page="500"
                      items-per-page-text="Linhas por página"
                      class="elevation-0"
                      item-key="id"
                      hide-default-footer
                    >
                            <template v-slot:top>
                              <v-progress-linear
                                v-if="isLoadingfluxo" 
                                indeterminate
                                color="rgb(71 130 180)"
                              ></v-progress-linear>
                            </template>

                           
                          <template v-slot:item="props">
                            <tr>
                              <td style="width: 12%;font-size:12px;">
                              {{ props.item.origem }}
                              </td>
                              <td style="width: 15%;font-size:12px;color:blue;">
                              {{
                                props.item.receber_previsto
                                  ? parseFloat(props.item.receber_previsto).toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    })
                                  : ''
                              }}
                            </td>
                            <td style="width: 15%;font-size:12px;color:red;">
                              {{
                                props.item.pagar_previsto
                                  ? parseFloat(props.item.pagar_previsto).toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    })
                                  : ''
                              }}
                            </td>
                            <td style="width: 15%;font-size:12px;">
                              {{
                                props.item.saldo_previsto
                                  ? parseFloat(props.item.saldo_previsto).toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    })
                                  : ''
                              }}
                            </td>
                            <td style="width: 15%;font-size:12px;color:blue;">
                              {{
                                props.item.receber_realizado
                                  ? parseFloat(props.item.receber_realizado).toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    })
                                  : ''
                              }}
                            </td>
                            <td style="width: 15%;font-size:12px;color:red;">
                              {{
                                props.item.pagar_realizado
                                  ? parseFloat(props.item.pagar_realizado).toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    })
                                  : ''
                              }}
                            </td>
                            <td style="width: 15%;font-size:12px;">
                              {{
                                props.item.saldo_realizado
                                  ? parseFloat(props.item.saldo_realizado).toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    })
                                  : ''
                              }}
                            </td>
   
                            </tr>
                          </template>

                    </v-data-table>

         </base-material-card>
          <br/>

    </v-col>
  </v-row>


  <div class="d-flex justify-end" >
          <div style="width: 240px;">
          
            <span @click="download_xls" title="Enviar Dados ao Stratws"  style="color:purple; font-size: 48px; margin-right: 24px; cursor: pointer;">
              <i class="fas fa-paper-plane"></i> <!-- Ícone de envio (avião de papel) -->
            </span>
            
            <br/>
          
          </div>
        </div>  

  </v-container>

</v-app> 
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import Fluxo from '../../services/fluxo'
import { ContentLoader } from 'vue-content-loader'
import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto


  export default {
    name: 'Cobranca',

    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,
    },

    mounted() {
    },

    data () {
      return {

        rules: {
            required: value => !!value || 'Obrigatório.',
          },

          empresa: 'Todas',
          search: "",
          dias : 31,
          selectedCompany: "",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          selectMode: "Todos",
          produto: 'Todos',
          isLoadingfluxo: false,
          opcoes: [],
          modos: [],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ"],
          tipos: ["Todos"],
          showProgress: false,
          currentGroup: null,
          isLoading : false,
          tipos_funerarios: [
                "Todos",
              ],

          fluxos: [],
          fluxo_header: [
              { text: 'Movimento', value: 'origem' , sortable: false  },
              { text: 'Prev. Receber', value: 'receber_previsto', sortable: false  },
              { text: 'Prev. Pagar', value: 'pagar_previsto', sortable: false  },
              { text: 'Prev. Saldo', value: 'saldo_previsto', sortable: false  },

              { text: 'Recebido', value: 'receber_realizado' , sortable: false  },
              { text: 'Pago', value: 'pagar_realizado' , sortable: false },
              { text: 'Saldo', value: 'saldo_realizado' , sortable: false  },
              { text: '', value: 'actions', sortable: false },

            ],


      };
      
    },

    methods: {

      formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },


    filterData(valores) {
        this.isLoading = true;
        this.selectedType = valores.selectedType;
        this.selectedStartDate = valores.selectedStartDate;
        this.selectedEndDate = valores.selectedEndDate;
        this.selectedCompany = valores.selectedCompany.join(",");       
        this.dashboard();
    },

 
    getEmpresa (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'
            case 'A FLOR DO CAFÉ' : return 'purple lighten-1'

              default: 'success'
            }
      },

 
      getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
      },

      getStatus (status) {
            switch (status) {
            case 'Ativo': return 'blue lighten-1'
            case 'Cancelado' : return 'red lighten-1'
             default: 'success'
          }
      },


      formatDate(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },


      download_xls(){
        alert('Em Manutenção')
      },


      dashboard() {
          if(this.selectedCompany === '') return
 
          const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
          const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

                this.isLoadingfluxo= true;
                this.fluxos = [];

                Fluxo.fluxo(formattedStartDate, formattedEndDate, this.selectedCompany).then(response => {
                            if(response.status === 200){
                              this.fluxos = response.data;
                            } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.isLoadingfluxo = false;
                          });

      },

      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },
    },
  }

  function formatDateConveter(date) {
          const year = date.getFullYear(); // Obtém o ano
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Obtém o mês (adiciona 1 e garante dois dígitos)
          const day = String(date.getDate()).padStart(2, '0'); // Obtém o dia (garante dois dígitos)
          return `${year}-${month}-${day}`;
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }


</script>

<style scoped>

  .v-progress-circular {
    margin: 1rem;
  }

  .custom-gray-background {
    height: 80px;
  }

  
  .vuetify__expand-icon {
  display: none !important; /* Oculta os ícones de expandir */
}
  /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
  .fill-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }
  
  /* Centralize horizontalmente */
  .justify-center {
    justify-content: center;
  }

  .card-loader {
    height: 100%; /* Define a altura do content-loader como 100% para preencher o v-card */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .v-data-table-header-mobile .v-data-table-header-group {
  display: none !important; /* Oculta os botões de expansão de grupo */
  }
  
  
  </style>
